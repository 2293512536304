import 'magnific-popup'

jQuery( document ).ready(function($) {
  // Mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.menu-header-menu-container').slideToggle()
  })

  $('.slider-artist-thumbs').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    mobileFirst: true,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 7,
        }
      }
    ]
  });

  const artistFeaturedImage = $('.artist-featured-image')
  
  $('.slider-artist-thumbs .thumb-item').click(function(e){
    const thisSrc = $(this).attr('src')
    $(artistFeaturedImage).attr('href', thisSrc)
    $(artistFeaturedImage).find('img').attr('src', thisSrc)
  })

  // Slider
  $('.hero-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    arrows: true,
    dots: true,
    // responsive: [
    //   {
    //     breakpoint: 992,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       arrows: false,
    //       dots: true
    //     }
    //   }
    // ]
  });

  $('.releases-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    dots: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        }
      }
    ]
  });

  $('.slider-videos-innerpage').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    dots: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.labels-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    dots: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        }
      }
    ]
  });

  $('.videos-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    dots: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  });

  $('.news-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: true,
    nextArrow: '<button type="button" class="slick-next"> <i class="fas fa-chevron-right"></i> </button>',
    prevArrow: '<button type="button" class="slick-prev"> <i class="fas fa-chevron-left"></i> </button>',
    dots: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1079,
        settings: {
          slidesToShow: 4,
        }
      }
    ]
  });

  function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  const allVideos = document.querySelectorAll('.video-slide')

  allVideos.forEach(video => {
    const videoUrl = video.href
    const videoID = youtube_parser(videoUrl)
    const videoThumbnail = `<img src="//img.youtube.com/vi/${videoID}/0.jpg">`
    video.querySelector(`.video-thumb`).innerHTML = videoThumbnail
    if ($("body").hasClass("page-template-videos")) {
      const thisVideoArtist = video.querySelector('.video-artist').innerHTML.toLowerCase().replace(/ /g,'')
      $(video).addClass(thisVideoArtist)
    }
  }) 


  if ($("body").hasClass("page-template-releases")) {
    const allReleases = document.querySelectorAll('.release-slide')
    allReleases.forEach(release => {
      const thisReleaseArtist = release.querySelector('.artist').innerHTML.toLowerCase().replace(/ /g,'')
      $(release).addClass(thisReleaseArtist)
    })
  }

  $('.filter-item-releases').click(function(e){
    e.preventDefault()
    $('.release-slide').removeClass('active')
    $('.filter-item').removeClass('active')
    $(this).addClass('active')
    $(`.${targetArtist}`).addClass('active')
  })
  
  $('.releases-filter').on('change', function (e) {
    const targetArtist = $(this).children("option:selected").attr('data-attribute')
    const targetResults = $(`.${targetArtist}`).length
    $('.release-slide').removeClass('active')
    $('.filter-item').removeClass('active')
    $(`.${targetArtist}`).addClass('active')
    $('.no-results').hide()
    if(targetResults === 0){
      $('.no-results').show()
    } else{
      $(`.${targetArtist}`).addClass('active')
    }
    if(targetArtist === 'all'){
      $('.release-slide').addClass('active')
      $('.no-results').hide()
    }
  });

  $('.videos-filter').on('change', function (e) {
    const targetArtist = $(this).children("option:selected").attr('data-attribute')
    const targetResults = $(`.${targetArtist}`).length
    $('.video-slide').removeClass('active')
    $('.filter-item').removeClass('active')
    $('.no-results').hide()
    if(targetResults === 0){
      $('.no-results').show()
    } else{
      $(`.${targetArtist}`).addClass('active')
    }
    if(targetArtist === 'all'){
      $('.video-slide').addClass('active')
      $('.no-results').hide()
    }
  });

  $('.news-filter').on('change', function (e) {
    const targetArtist = $(this).children("option:selected").attr('data-attribute')
    const targetResults = $(`.${targetArtist}`).length
    $('.news-slide').removeClass('active')
    $('.filter-item').removeClass('active')
    $(`.${targetArtist}`).addClass('active')
    $('.no-results').hide()
    if(targetResults === 0){
      $('.no-results').show()
    } else{
      $(`.${targetArtist}`).addClass('active')
    }
    if(targetArtist === 'all'){
      $('.news-slide').addClass('active')
      $('.no-results').hide()
    }
  });

  $('.filter-item-video').click(function(e){
    e.preventDefault()
    $('.video-slide').removeClass('active')
    $('.filter-item').removeClass('active')
    $(this).addClass('active')
    const targetArtist = $(this).attr('data-attribute')
    $(`.${targetArtist}`).addClass('active')
  })

  if ($("body").hasClass("blog")) {
    const allNews = document.querySelectorAll('.news-slide')
    allNews.forEach(news => {
      const thisNewsArtist = news.querySelector('.artist').innerHTML.toLowerCase().replace(/ /g,'')
      $(news).addClass(thisNewsArtist)
    })
  }


  $('.filter-item-news').click(function(e){
    e.preventDefault()
    $('.news-slide').removeClass('active')
    $('.filter-item').removeClass('active')
    $(this).addClass('active')
    const targetNews = $(this).attr('data-attribute')
    $(`.${targetNews}`).addClass('active')
  })
  
});


// $('.image-popup-vertical-fit').magnificPopup({
//   type: 'image',
//   closeOnContentClick: true,
//   mainClass: 'mfp-img-mobile',
//   image: {
//     verticalFit: true
//   }
// });

$('.image-popup-fit-width').magnificPopup({
  type: 'image',
  closeOnContentClick: true,
  image: {
    verticalFit: false
  }
});

$('.new-gallery-item').magnificPopup({
  type: 'image',
  gallery:{
    enabled:true
  }
});

$('.image-popup-no-margins').magnificPopup({
  type: 'image',
  closeOnContentClick: true,
  closeBtnInside: false,
  fixedContentPos: true,
  mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
  image: {
    verticalFit: true
  },
  zoom: {
    enabled: true,
    duration: 300 // don't foget to change the duration also in CSS
  }
});